import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { nameValidation, descriptionValidation, endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import tablesApi from "../../hooks/GET/tables";
import { allTablesListApi, tablePrimaryKeyApi } from "../../hooks/GET/tablesDetail";
import postEventApi from "../../hooks/POST/event";
import { useSelector } from "react-redux";
import getUpdateValue from "../../config/getUpdateValue.js";
import { DatabaseCredsApi } from "../../hooks/GET/DatabaseCreds";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function TableDialog(props) {
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const [databaseCreds, setDatabaseCreds] = useState([]);
  useEffect(() => {
    DatabaseCredsApi()
      .then((data) => {
        setDatabaseCreds(data);
      })
      .catch((error) => console.log(error));
  }, []);

  const [databaseTableNameList, setDatabaseTableNameList] = useState([]);
  const [schemaList, setSchemaList] = useState([]);

  const {
    open,
    handleClose,
    dataId,
    dataName,
    dataDescription,
    dataDatabaseId,
    dataConnectionName,
    dataAccessLevel,
    dataDatabaseTableName,
    dataSchema
  } = props;
  const initialState = {
    name: "",
    nameError: "",
    databaseId: "",
    connectionName:"",
    databaseTableName: [],
    databaseError: "",
    description: "",
    descriptionError: "",
  };

  const [state, setState] = useState({ ...initialState });

  const handleConnectionNameChange = (event) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      connectionName:value.connectionName,
      databaseId: value.id,
      databaseTableName: [],
    }));
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      name: value,
      nameError: value && !value.match(nameValidation.regex) ? nameValidation.message : "",
    }));
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      description: value,
      descriptionError: value && !value.match(descriptionValidation.regex) ? descriptionValidation.message : "",
    }));
  };

  const [tableList, setTableList] = useState([]);

  useEffect(() => {
    if (open) {
      tablesApi()
        .then((data) => {
          setTableList(data);
        })
        .catch((error) => console.log(error));
    }
  }, [open]);

  const initialTableList = (databaseTableNameList, tableList) => {
    const nameList = databaseTableNameList.map((v) => ({ ...v, disabled: false }));
    nameList.map((x) => {
      var result = tableList.filter(
        (item) => item.databaseTableName === x.name && item.databaseName === x.databaseName && item.schema === x.schema
      );
      if (result.length > 0) {
        x.disabled = true;
      }
      return x;
    });
    return nameList;
  };

  const updateTableLlist = (databaseTableNameList, tableList) => {
    tablesApi()
      .then((data) => {
        setTableList(data);
      })
      .catch((error) => console.log(error));
    const initial = initialTableList(databaseTableNameList, tableList);
    return initial;
  };

  const handleDatabaseTableNameChange = (e) => {
    const value = e.target.value;
    const parsedValue = typeof value === "string" ? value.split(",").map((v) => JSON.parse(v)) : value.map((v) => JSON.parse(v));
  
    for (let i = 0; i < databaseTableNameList.length; i++) {
      if (state.connectionName) {
        if (parsedValue.some((val) => val.name === databaseTableNameList[i].name)) {
          databaseTableNameList[i].disabled = true;
        } else if (state.databaseTableName.some((val) => JSON.parse(val).name === databaseTableNameList[i].name)) {
          databaseTableNameList[i].disabled = false;
        }
      }
    }
  
    setState((prevState) => ({
      ...prevState,
      databaseTableName: parsedValue.map((v) => JSON.stringify(v)),
      databaseTableNameList: databaseTableNameList,
    }));
  };
  

  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      !state.name ||
        state.nameError ||
        !state.description ||
        state.descriptionError ||
        !state.connectionName ||
        state.databaseTableName.length ===0 ||
        state.databaseError
    );
  }, [state]);

  useEffect(() => {
    if (open && state.databaseId) {
      allTablesListApi(state.databaseId)
        .then((data) => {
          const datalist = initialTableList(data, tableList);
          setDatabaseTableNameList(datalist);
        })
        .catch((error) => console.log(error));
    }
  }, [tableList, state.databaseId, open]);

  const [accessLevel, setAccessLevel] = useState([]);
  useEffect(() => {
    if (open && state.databaseTableName.length>0 && state.databaseId) {
      const parsedObject = JSON.parse(state.databaseTableName[0]);
      const value = parsedObject.name;
      tablePrimaryKeyApi(value, state.databaseId)
        .then((data) => {
          if (data.length === 0) {
            setAccessLevel("Read only");
          } else {
            setAccessLevel("Read-Write");
          }
        })
        .catch((error) => console.log(error));
    }
  }, [tableList, state.databaseTableName, open]);

  const handleClose2 = (value) => {
    handleClose(value);
    setDatabaseTableNameList(initialTableList(databaseTableNameList, tableList));
  };

  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const handleCatchLogic = (error) => {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    };
    const existingRecord = {
      name: props.dataName,
      description: props.dataDescription,
      connectionName: props.dataConnectionName,
      schema: dataSchema,
      databaseTableName: props.dataDatabaseTableName,
      accessLevel: props.dataAccessLevel,
      databaseId: props.dataDatabaseId
    };
    const requestBody = {
      name: state.name,
      description: state.description,
      connectionName: state.connectionName,
      schema: dataSchema,
      databaseTableName: state.databaseTableName.map((e) => JSON.parse(e).name)[0],
      accessLevel: accessLevel,
      databaseId: state.databaseId,
    };

    const { updatedExistingRecord, updatedUpdateValue } = getUpdateValue(existingRecord, requestBody);
    // Check if 'databaseId' key exists
    if (!updatedExistingRecord.hasOwnProperty('databaseId')) {
      updatedExistingRecord.databaseId = props.dataDatabaseId;
      updatedExistingRecord.connectionName = props.dataConnectionName;
    }
    if (!updatedUpdateValue.hasOwnProperty('databaseId')) {
      updatedUpdateValue.databaseId = state.databaseId;
      updatedUpdateValue.connectionName = state.connectionName;
    }

    setSubmitBtnLoading(true);
    if (!dataId) {
      axios
        .post(`${endpoint}/api/table`, requestBody)
        .then((response) => {
          setSubmitBtnLoading(false);
          customToast.success("Table added successfully");
          setDatabaseTableNameList(updateTableLlist(databaseTableNameList, tableList, requestBody.connectionName));
          handleClose(true);
          postEventApi({
            action: 'created table ' + requestBody.name,
            status: 'success',
            tableId: response.data.id,
            tableName: requestBody.name,
            tableDatabaseTableName: requestBody.databaseTableName,
            updateValue: JSON.stringify(requestBody),
            userId: userId,
            userName: userName,
          });
        })
        .catch((error) => {
          handleCatchLogic(error);
          postEventApi({
            action: 'created table ' + requestBody.name,
            status: 'failure',
            tableName: requestBody.name,
            tableDatabaseTableName: requestBody.databaseTableName,
            updateValue: JSON.stringify(requestBody),
            userId: userId,
            userName: userName,
          });
        });
    } else {
      axios
        .put(`${endpoint}/api/table/${dataId}`, requestBody)
        .then(() => {
          setSubmitBtnLoading(false);
          customToast.success("Table updated successfully");
          handleClose(true);
          postEventApi({
            action: 'updated table ' + state.name,
            status: 'success',
            tableId: dataId,
            tableName: requestBody.name,
            tableDatabaseTableName: requestBody.databaseTableName,
            existingRecord: JSON.stringify(updatedExistingRecord),
            updateValue: JSON.stringify(updatedUpdateValue),
            userId: userId,
            userName: userName,
          });
        })
        .catch((error) => {
          handleCatchLogic(error);
          postEventApi({
            action: 'updated table ' + existingRecord.name,
            status: 'failure',
            tableId: dataId,
            tableName: existingRecord.name,
            tableDatabaseTableName: existingRecord.databaseTableName,
            existingRecord: JSON.stringify(updatedExistingRecord),
            updateValue: JSON.stringify(updatedUpdateValue),
            userId: userId,
            userName: userName,
          });
        });
    }
  };

  useEffect(() => {
    if (open) {
      setState({
        ...initialState,
        ...(dataName && { name: dataName }),
        ...(dataDescription && { description: dataDescription }),
        ...(dataDatabaseId && { databaseId: dataDatabaseId }),
        ...(dataConnectionName && dataDatabaseId && { 
          connectionName: dataConnectionName,
        }),    
        ...(dataDatabaseTableName && {
          databaseTableName: [{ name: dataDatabaseTableName }].map((e) => JSON.stringify(e)),
        }),
      });
      setDatabaseTableNameList(updateTableLlist(databaseTableNameList, tableList));
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={() => handleClose2(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Name"
                name="name"
                value={state.name}
                onChange={handleNameChange}
                error={state.nameError ? true : false}
                helperText={state.nameError}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Description"
                name="description"
                value={state.description}
                onChange={handleDescriptionChange}
                error={state.descriptionError ? true : false}
                helperText={state.descriptionError}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel required>Connection Name</InputLabel>
                <Select
                  value={state.connectionName}
                  onChange={handleConnectionNameChange}
                  input={<OutlinedInput label="Connection Name" />}
                  MenuProps={MenuProps}
                  renderValue={(selected) => selected}
                >
                  {databaseCreds.map((option) => (
                    <MenuItem key={option.id} value={{ id: option.id, connectionName: option.connectionName }}>
                      {option.connectionName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {state.databaseId && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel required>Database Table Name</InputLabel>
                  <Select
                    value={state.databaseTableName.length === 0 ? '' : state.databaseTableName}
                    onChange={handleDatabaseTableNameChange}
                    input={<OutlinedInput label="Database Table Name" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value, idx) => (
                          <Chip key={idx} label={JSON.parse(value).name} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    error={state.databaseError ? true : false}
                    defaultValue=""
                  >
                    {databaseTableNameList.map((option, idx) => (
                      <MenuItem key={idx} value={JSON.stringify({ name: option.name })} disabled={option.disabled}>
                        <Checkbox
                          checked={state.databaseTableName.indexOf(JSON.stringify({ name: option.name })) > -1}
                        />
                        <ListItemText primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(state.databaseError)}>{state.databaseError}</FormHelperText>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
        <LoadingButton
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          type="submit"
          disabled={Boolean(isSubmitBtnDisbaled)}
          loading={submitBtnLoading}
        >
          Save
        </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

