import { toast } from "react-toastify";
import { Alert } from "@mui/material";

const buildOptions = (options) => {
  return {
    ...(options && { ...options }),
    hideProgressBar: true,
    closeButton: false,
    style: { padding: 0, borderRadius: 10, minHeight: 0 },
    bodyStyle: { padding: 0 },
  };
};

// Function to generate a unique toastId based on message and severity
const generateToastId = (msg, severity) => `${severity}-${msg}`;

const customToast = {
  success(msg, options) {
    const toastId = generateToastId(msg, 'success');
    if (!toast.isActive(toastId)) {
      return toast(
        ({ closeToast }) => (
          <Alert severity="success" onClose={closeToast}>
            {msg}
          </Alert>
        ),
        { ...buildOptions(options), toastId }
      );
    }
  },
  error(msg, options) {
    const toastId = generateToastId(msg, 'error');
    if (!toast.isActive(toastId)) {
      return toast(
        ({ closeToast }) => (
          <Alert severity="error" onClose={closeToast}>
            {msg}
          </Alert>
        ),
        { ...buildOptions(options), toastId }
      );
    }
  },
  info(msg, options) {
    const toastId = generateToastId(msg, 'info');
    if (!toast.isActive(toastId)) {
      return toast(
        ({ closeToast }) => (
          <Alert severity="info" onClose={closeToast}>
            {msg}
          </Alert>
        ),
        { ...buildOptions(options), toastId }
      );
    }
  },
  warning(msg, options) {
    const toastId = generateToastId(msg, 'warning');
    if (!toast.isActive(toastId)) {
      return toast(
        ({ closeToast }) => (
          <Alert severity="warning" onClose={closeToast}>
            {msg}
          </Alert>
        ),
        { ...buildOptions(options), toastId }
      );
    }
  },
};

export { toast, customToast };
