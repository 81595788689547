import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Stack,
  TableContainer,
  Pagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SyncIcon from '@mui/icons-material/Sync';
import axios from "axios";

import DatabaseCredsDialog from "./DatabaseCredsDialog";
import { DatabaseCredsApi } from "../../hooks/GET/DatabaseCreds";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useSelector } from "react-redux";

function DatabaseCreds() {
  // users list logic start
  const [databaseCredsList, setDatabaseCredsList] = useState([]);
  const [refetchDatabaseCredsList, setRefetchDatabaseCredsList] = useState(0);
  const [totalResult, setTotalResult] = useState(0);

  const isSmall = useMediaQuery('(max-width:536px)');
  const isSmall2 = useMediaQuery('(max-width:600px)');
  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);
  const userIsDefault = useSelector((state) => state.user.isDefault);

  useEffect(() => {
    DatabaseCredsApi()
      .then((data) => {
        setDatabaseCredsList(data);
      })
      .catch((error) => console.log(error));
  }, [refetchDatabaseCredsList]);

  const handleDelete = (id) => {
    const fieldValues = [...databaseCredsList];
    axios
      .delete(`${endpoint}/api/database/${id}`)
      .then(() => {
        fieldValues.splice(fieldValues.findIndex(obj => obj.id === id), 1);

        setDatabaseCredsList(fieldValues);

        customToast.success("Database deleted successfully");
      })
      .catch((error) => {
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
      });
  };

  const handleRefresh = (id) => {
    axios
      .put(`${endpoint}/api/database/refresh/${id}`)
      .then(() => {
        customToast.success("Database refreshed successfully");
      })
      .catch((error) => {
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
      });
  };
  // users list logic end

  //table pagination logic start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPageOption = [5, 10, 20];

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalResult - page * rowsPerPage);
  //table pagination logic end

  // database dialog logic start
  const [dbDialogState, setDBDialogState] = useState({
    open: false,
    id: 0,
    connectionName: "",
    host: "",
    databaseName: "",
    schema:"",
    username: "",
    dialect: "",
    port: "",
  });

  const handleDialogOpen = (id, connectionName, host, databaseName, schema, username, dialect, port) => {
    setDBDialogState({
      open: true,
      id: id,
      connectionName: connectionName,
      host: host,
      databaseName: databaseName,
      schema: schema,
      username: username,
      dialect: dialect,
      port: port
    });
  };

  const handleUserDialogClose = (doRefetch) => {
    if (doRefetch) {
      setRefetchDatabaseCredsList(refetchDatabaseCredsList + 1);
    }
    setDBDialogState({
      open: false,
      id: 0,
      connectionName: "",
      host: "",
      databaseName: "",
      schema: "",
      username: "",
      dialect: "",
      port: "",
    });
  };
  // database dialog logic end

  // delete confirmation dialog logic start
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    title: "",
    message: "",
    id: "",
  });

  const handleDeleteDialogOpen = (title, message, id) => {
    setDeleteDialogState({
      open: true,
      title: title,
      message: message,
      id: id,
    });
  };

  const handleDeleteDialogClose = (isConfirmed, id) => {
    // if confirmed than delete
    if (isConfirmed) {
      handleDelete(id);
    }
    setDeleteDialogState({
      open: false,
      title: "",
      message: "",
      id: "",
    });
  };
  // delete confirmation dialog logic end

    // refresh confirmation dialog logic start
    const [refreshDialogState, setRefreshDialogState] = useState({
      open: false,
      title: "",
      message: "",
      id: "",
    });
  
    const handleRefreshDialogOpen = (title, message, id) => {
      setRefreshDialogState({
        open: true,
        title: title,
        message: message,
        id: id,
      });
    };
  
    const handleRefreshDialogClose = (isConfirmed, id) => {
      // if confirmed than refresh
      if (isConfirmed) {
        handleRefresh(id);
      }
      setRefreshDialogState({
        open: false,
        title: "",
        message: "",
        id: "",
      });
    };
    // delete confirmation dialog logic end

  // search logic start
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setPage(0);

  };

  const getFilteredDatabaseCredsList = useMemo(() => {

    let filterdata = databaseCredsList;
    filterdata = filterdata.filter((obj) =>
      JSON.stringify(obj).match(new RegExp(search, "gi"))
    );

    setTotalResult(filterdata.length);
    if (filterdata.length !== 0) {
      const lastPage = Math.ceil(filterdata.length / rowsPerPage);
      if (lastPage === page)
        setPage(lastPage - 1);
    }
    return filterdata;
  }, [search, databaseCredsList, page, rowsPerPage]);

  // search logic end

  return (
    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography sx={{ display: "contents" }}>Show</Typography>
          <TextField
            select
            name="rowsPerPage"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
            sx={{ verticalAlign: "middle", margin: 1, minWidth: 75 }}
          >
            {rowsPerPageOption.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ display: "contents" }}>entries</Typography>
          <TextField
            name="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ verticalAlign: "middle", marginLeft: isSmall ? 0 : 1, marginTop: 1, marginRight: isSmall ? 2 : 1, marginBottom: 1 }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleDialogOpen(null, "", "", "", "", "", "", "")}
          >
            Create
          </Button>
        </Grid>
      </Grid>

      {/* Table part */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {userIsDefault && <TableCell align="center">HashedId</TableCell>}
              <TableCell align="center" >Connection Name</TableCell>
              <TableCell align="center" >Host</TableCell>
              <TableCell align="center" >Database Name</TableCell>
              <TableCell align="center" >Schema</TableCell>
              <TableCell align="center" >Username</TableCell>
              <TableCell align="center" >Dialect</TableCell>
              <TableCell align="center" >Port</TableCell>
              <TableCell align="center" >Active</TableCell>
              <TableCell align="center" >Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? getFilteredDatabaseCredsList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : getFilteredDatabaseCredsList
            ).map((fieldValue, idx) => (
              <TableRow key={idx} hover>
                {userIsDefault && <TableCell align="center">{fieldValue.hashedId}</TableCell>}
                <TableCell align="center">{fieldValue.connectionName}</TableCell>
                <TableCell align="center">{fieldValue.host}</TableCell>
                <TableCell align="center">{fieldValue.databaseName}</TableCell>
                <TableCell align="center">{fieldValue.schema}</TableCell>
                <TableCell align="center">{fieldValue.username}</TableCell>
                <TableCell align="center">{fieldValue.dialect}</TableCell>
                <TableCell align="center">{fieldValue.port}</TableCell>
                <TableCell align="center">{fieldValue.active ? 'Yes' : 'No'}</TableCell>
                <TableCell align="center">
                  <Stack direction="row" justifyContent="center" >
                    <Tooltip title="Edit" >
                      <span>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() =>
                            handleDialogOpen(
                              fieldValue.id,
                              fieldValue.connectionName,
                              fieldValue.host,
                              fieldValue.databaseName,
                              fieldValue.schema,
                              fieldValue.username,
                              fieldValue.dialect,
                              fieldValue.port,
                            )
                          }
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Refresh" >
                      <span>
                        <IconButton
                          size="small"
                          color="secondary"
                          onClick={() =>
                            handleRefreshDialogOpen(
                              `Refresh database '${fieldValue.databaseName}'`,
                              "Are you sure you want to refresh this database?",
                              fieldValue.id
                            )
                          }
                        >
                          <SyncIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete" >
                      <span>
                        <IconButton
                          // disabled={userId === fieldValue.id}
                          size="small"
                          color="error"
                          onClick={() =>
                            handleDeleteDialogOpen(
                              `Delete database '${fieldValue.databaseName}'`,
                              "Are you sure you want to delete this database?",
                              fieldValue.id
                            )
                          }
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={10}>
                  {totalResult === 0 && "No database available to display"}
                  {/* {databaseCredsList.length === 0 && "No users available to display"} */}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DatabaseCredsDialog
        open={dbDialogState.open}
        handleClose={handleUserDialogClose}
        dataId={dbDialogState.id}
        dataConnectionName={dbDialogState.connectionName}
        dataHost={dbDialogState.host}
        dataDatabaseName={dbDialogState.databaseName}
        dataUsername={dbDialogState.username}
        dataPassword={dbDialogState.password}
        dataDialect={dbDialogState.dialect}
        dataPort={dbDialogState.port}
        dataSchema={dbDialogState.schema}
      />

      <ConfirmationDialog
        open={deleteDialogState.open}
        handleClose={handleDeleteDialogClose}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        data={deleteDialogState.id}
      />

      <ConfirmationDialog
        open={refreshDialogState.open}
        handleClose={handleRefreshDialogClose}
        title={refreshDialogState.title}
        message={refreshDialogState.message}
        data={refreshDialogState.id}
      />

      {totalResult > 0 && (
        <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page)}
              disabled={page === 0}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResult / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              color="secondary"
              hidePrevButton
              hideNextButton
              size={isSmall2 ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page + 2)}
              disabled={Math.ceil(totalResult / rowsPerPage) === page + 1}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default DatabaseCreds;

