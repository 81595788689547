import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const DatabaseCredsApi = async () => {
  try {
    const apiLink = `${endpoint}/api/database`;
    const response = await axios.get(apiLink);
    const data = response.data;
    const dataArray = [];
    if (data) {
      data.forEach((elem) => {
        const obj = {
          id: elem.id,
          connectionName: elem.connectionName || "",
          host: elem.host || "",
          databaseName: elem.databaseName || "",
          username: elem.username || "",
          schema: elem.schema || "",
          dialect: elem.dialect || "",
          port: elem.port || "",
          active: elem.active || "",
          hashedId: elem.hashedId || "",
        };
        dataArray.push(obj);
      });
    }

    return dataArray;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export { DatabaseCredsApi };

