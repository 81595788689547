import React, { useState } from "react";
// useMediaQuery 
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import HistoryIcon from '@mui/icons-material/History';
import LabelIcon from "@mui/icons-material/Label";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";

import { app_title } from "../../config";
import Loading from "../../components/Loading";

import Users from "./Users";
import Table from "./Table";
import Event from "./Event";
import DatabaseCreds from "./DatabaseCreds";

function Admin() {
  // user detail
  const user = useSelector((state) => state.user);

  // branding details
  const brandingDtl = useSelector((state) => state.branding);

  // media query for mobile screen
  // const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // tab value logic start
  const [value, setValue] = useState("Users");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  // tab value logic end

  return (
    <Box
      sx={{
        overflow: "auto",
        paddingX: { xs: 1, sm: 5 },
        paddingY: 3,
        minHeight: "82vh",
      }}
    >
      <Helmet>
        <title>
          Admin - {value} | {brandingDtl.pageTitle || app_title}
        </title>
      </Helmet>
      <Grid container sx={{ minWidth: 300 }}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          {!user.id ? (
            <Loading />
          ) : !user.isAdmin ? (
            <Navigate to="/dashboard" />
          ) : (
            <TabContext value={value}>
              <TabList
                onChange={handleChange}
                centered
                // centered={!isMobile}
                // variant={isMobile ? "scrollable" : "standard"}
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab
                  label="Users"
                  icon={<PeopleAltIcon />}
                  iconPosition="start"
                  value="Users"
                />
                <Tab
                  label="Tables"
                  icon={<BookmarksIcon />}
                  iconPosition="start"
                  value="Table"
                />
                <Tab
                  label="Events"
                  icon={<HistoryIcon />}
                  iconPosition="start"
                  value="Event"
                />
                {user.isAdmin &&
                <Tab
                  label="DatabaseCreds"
                  icon={<LabelIcon />}
                  iconPosition="start"
                  value="DatabaseCreds"
                />
}
              </TabList>
              <TabPanel value="Users">
                <Users />
              </TabPanel>
              <TabPanel value="Table">
                <Table />
              </TabPanel>
              <TabPanel value="Event">
                <Event />
              </TabPanel>
              {user.isAdmin &&
              <TabPanel value="DatabaseCreds">
                <DatabaseCreds />
              </TabPanel>
}
            </TabContext>
          )}
        </Box>
      </Grid>
    </Box>
  );
}

export default Admin;
