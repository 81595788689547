import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Stack,
  TableContainer,
  Pagination,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import axios from "axios";

import TableDialog from "./TableDialog";
import tablesApi from "../../hooks/GET/tables";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import postEventApi from "../../hooks/POST/event";
import { useSelector } from "react-redux";

function Tables() {

  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  // table list logic start
  const [tableList, setTableList] = useState([]);
  const [refetchTableList, setRefetchTableList] = useState(0);
  const [totalResult, setTotalResult] = useState(0);
  const isSmall = useMediaQuery('(max-width:536px)');
  const isSmall2 = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    tablesApi()
      .then((data) => {
        setTableList(data);
      })
      .catch((error) => console.log(error));
  }, [refetchTableList]);

  const handleDelete = (id) => {
    const fieldValues = [...tableList];
    const deletedTable = fieldValues.find(obj => obj.id === id)
    axios
      .delete(`${endpoint}/api/table/${id}`)
      .then(() => {

        fieldValues.splice(fieldValues.findIndex(obj => obj.id === id), 1);

        setTableList(fieldValues);

        customToast.success("Table deleted successfully");
        postEventApi({action:'delete table ' + deletedTable.name,
                      status:'success',
                      tableId:id,
                      tableName:deletedTable.name,
                      tableDatabaseTableName: deletedTable.databaseTableName,
                      existingRecord:JSON.stringify(deletedTable),
                      userId:userId,
                      userName:userName,
                    })
      })
      .catch((error) => {
        if (error.response) {
          customToast.error(error.response.data?.message);
        } else {
          customToast.error(error.message);
        }
        postEventApi({action:'deleted table ' + deletedTable.name,
                      status:'failure',
                      tableId:id,
                      tableName:deletedTable.name,
                      tableDatabaseTableName: deletedTable.databaseTableName,
                      existingRecord:JSON.stringify(deletedTable),
                      userId:userId,
                      userName:userName,
                    })
      });
  };
  // table list logic end

  //table pagination logic start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const rowsPerPageOption = [5, 10, 20];

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage -
    Math.min(rowsPerPage, totalResult - page * rowsPerPage);
  //table pagination logic end

  // table dialog logic start
  const [tableDialogState, setTableDialogState] = useState({
    open: false,
    id: 0,
    name: "",
    description: "",
    connectionName:"",
    databaseTableName: "",
    accessLevel: "",
    databaseId: "",
    schema:"",
  });

  const handleTableDialogOpen = (id, name, description, connectionName, databaseTableName, accessLevel,databaseId,schema) => {
    setTableDialogState({
      open: true,
      id: id,
      name: name,
      description: description,
      connectionName: connectionName,
      databaseTableName: databaseTableName,
      accessLevel: accessLevel,
      databaseId: databaseId,
      schema: schema
    });
  };

  const handleTableDialogClose = (doRefetch) => {
    if (doRefetch) {
      setRefetchTableList(refetchTableList + 1);
    }
    setTableDialogState({ open: false, id: 0, name: "", description: "", connectionName:"",databaseTableName:"",accessLevel:"",databaseId:"",schema:""});
  };
  // table dialog logic end

  // delete confirmation dialog logic start
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    title: "",
    message: "",
    id: "",
  });

  const handleDeleteDialogOpen = (title, message, id) => {
    setDeleteDialogState({
      open: true,
      title: title,
      message: message,
      id: id,
    });
  };

  const handleDeleteDialogClose = (isConfirmed, id) => {
    // if confirmed than delete
    if (isConfirmed) {
      handleDelete(id);
    }
    setDeleteDialogState({
      open: false,
      title: "",
      message: "",
      id: "",
    });
  };
  // delete confirmation dialog logic end

  // search logic start
  const [search, setSearch] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    setPage(0);
  };

  const getFilteredTableList = useMemo(() => {
    let filterdata = tableList;

    filterdata = filterdata.filter((obj) =>
      JSON.stringify(obj).match(new RegExp(search, "gi"))
    );
    setTotalResult(filterdata.length);

    if (filterdata.length !== 0) {
      const lastPage = Math.ceil(filterdata.length / rowsPerPage);
      if (lastPage === page)
        setPage(lastPage - 1);
    }
    return filterdata;
  }, [search, tableList, page, rowsPerPage]);
  // search logic end

  const tooltipColTitle = "Read only: The table doesn't have a primary key.\r\nRead-Write: The table has a primary key."

  return (

    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >

        <Grid item >
          <Typography sx={{ display: "contents" }}>Show</Typography>
          <TextField
            select
            name="rowsPerPage"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            size="small"
            sx={{ verticalAlign: "middle", margin: 1, minWidth: 75 }}
          >
            {rowsPerPageOption.map((option, idx) => (
              <MenuItem key={idx} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ display: "contents" }}>entries</Typography>
          <TextField
            name="search"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search..."
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ verticalAlign: "middle", marginLeft: isSmall ? 0 : 1, marginTop: 1, marginRight: isSmall ? 2 : 1, marginBottom: 1 }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleTableDialogOpen(null, "", "","","")}
          >
            Create
          </Button>
        </Grid>
      </Grid>

      {/* Table part */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr. No.</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Connection Name</TableCell>
              <TableCell align="center">Database Table Name</TableCell>
              <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipColTitle}</span>} arrow>
              <TableCell align="center">Access Level</TableCell>
              </Tooltip>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? getFilteredTableList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : getFilteredTableList
            ).map((fieldValue, idx) => (
              <TableRow key={idx} hover>
                <TableCell align="center">{page * rowsPerPage + idx + 1}</TableCell>
                <TableCell align="center">{fieldValue.name}</TableCell>
                <TableCell align="center">{fieldValue.description}</TableCell>
                <TableCell align="center">{fieldValue.connectionName}</TableCell>
                <TableCell align="center">{fieldValue.databaseTableName}</TableCell>
                <TableCell align="center">{fieldValue.accessLevel}</TableCell>
                <TableCell align="center">
                  <Stack direction="row" justifyContent="center">
                    <Tooltip title="Edit">
                      <IconButton
                        size="small"
                        color="secondary"
                        onClick={() =>
                          handleTableDialogOpen(
                            fieldValue.id,
                            fieldValue.name,
                            fieldValue.description,
                            fieldValue.connectionName,
                            fieldValue.databaseTableName,
                            fieldValue.accessLevel,
                            fieldValue.databaseId,
                          )
                        }
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() =>
                          handleDeleteDialogOpen(
                            `Delete table '${fieldValue.name}'`,
                            "Are you sure you want to delete this table?",
                            fieldValue.id
                          )
                        }
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell align="center" colSpan={7}>
                  {totalResult === 0 &&
                    "No table available to display"}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TableDialog
        open={tableDialogState.open}
        handleClose={handleTableDialogClose}
        dataId={tableDialogState.id}
        dataName={tableDialogState.name}
        dataDescription={tableDialogState.description}
        dataConnectionName={tableDialogState.connectionName}
        dataDatabaseTableName={tableDialogState.databaseTableName}
        dataAccessLevel={tableDialogState.accessLevel}
        dataDatabaseId={tableDialogState.databaseId}
        dataSchema={tableDialogState.schema}
      />

      <ConfirmationDialog
        open={deleteDialogState.open}
        handleClose={handleDeleteDialogClose}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        data={deleteDialogState.id}
      />

      {totalResult > 0 && (
        <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={1} wrap='nowrap'>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page)}
              disabled={page === 0}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResult / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              color="secondary"
              hidePrevButton
              hideNextButton
              size={isSmall2 ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page + 2)}
              disabled={
                Math.ceil(totalResult / rowsPerPage) === page + 1
              }
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

export default Tables;

