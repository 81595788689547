import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Pagination,
  useMediaQuery,
  Button,
  Stack,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import MaterialReactTable from 'material-react-table';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import axios from "axios";
import ConfirmationDialog from "../components/ConfirmationDialog";
import DashboardTableDialog from "./DashboardTableDialog";
import { app_title, endpoint } from "../config";
import { customToast } from "../lib/toastLib";
import userDetailApi from "../hooks/GET/userDetail";
import tablesApi from "../hooks/GET/tables";
import { tablesDetailApi, tablesColumnsSelectOptionsApi, tablePrimaryKeyApi, tableTotalCountApi } from "../hooks/GET/tablesDetail";
import postEventApi from "../hooks/POST/event";

function Dashboard() {
  
  const isSmall2 = useMediaQuery('(max-width:600px)');

  // userId
  const [userId, userName, isAdmin, isDefault] = useSelector((state) => [state.user.id, state.user.fullName, state.user.isAdmin, state.user.isDefault]);

  const [refetchTablesList, setRefetchTablesList] = useState(0);

  // branding details
  const brandingDtl = useSelector((state) => state.branding);

  // fetching user detail for table permission check start
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (userId) {
      userDetailApi(userId)
        .then((data) => {
          const permissionsId = data.permissions.map((e) => e.id);
          setPermissions(permissionsId);
        })
        .catch((error) => console.log(error));
    }
  }, [userId]);
  // fetching user detail for table permission check end

  //table pagination logic start
  const initialRowsPerPage = 50
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const rowsPerPageOption = [25, 50, 100, 200];

  // state logic start
  const [state, setState] = useState({
    table: "",
    dbCredId:"",
    databaseTableId:"",
    databaseTableName:"",
    databaseTableNameError:"",
    accessLevel:"",
  });
  const handleTableChange = (e) => {
    const value = e.target.value;
    var databaseTableNameValue = JSON.parse(value).databaseTableName
    var accessLevel = JSON.parse(value).accessLevel
    var databaseTableId = JSON.parse(value).id
    var dbCredId = JSON.parse(value).databaseId
    setState({ 
      ...state, 
      table: value, 
      dbCredId: dbCredId ? dbCredId :"",
      databaseTableId: databaseTableId ? databaseTableId :"",
      accessLevel: accessLevel ? accessLevel: false,
      databaseTableName: databaseTableNameValue ? databaseTableNameValue: false,
      databaseTableNameError: (databaseTableNameValue.length === 0) ? "Database Table is not exist" : "",
    });
    setPage(0);
    setRowsPerPage(initialRowsPerPage);
    setColumnsListCreate([]);
    setColumnFilters([]);
    setColsFilterOpionsFirst([]);
    setColsSelectOpions([]);
    setIsLoading(true);
    setRunTables(1);
  };
  // state logic end

    // table options logic start
    const [tableOptions, setTableOptions] = useState([]);

    useEffect(() => {
      tablesApi()
        .then((data) => {
          setTableOptions(data);
          // If there is only one table option, set it as the selected table
        if (data.length === 1) {
          const singleTableOption = JSON.stringify(data[0]);
          const { databaseTableName, accessLevel, id, databaseId } = data[0];
          setState({
            ...state,
            table: singleTableOption,
            dbCredId: databaseId || "",
            databaseTableId: id || "",
            accessLevel: accessLevel || false,
            databaseTableName: databaseTableName || false,
            databaseTableNameError: (databaseTableName.length === 0) ? "Database Table does not exist" : "",
          });
          setPage(0);
          setRowsPerPage(initialRowsPerPage);
          setColumnsListCreate([]);
          setColumnFilters([]);
          setColsFilterOpionsFirst([]);
          setColsSelectOpions([]);
          setIsLoading(true);
          setRunTables(1);
        }
  
        })
        .catch((error) => console.log(error));
    }, []);
    // table options logic end
  

  const handleChangePage = (e, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //table pagination logic end

  //  Column Filter start
  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
    if(columnFilters.length > 0){
      for(var key in columnFilters){
        var selectedData = tableColumns[tableColumns.map(function (item) { return item.accessorKey; }).indexOf(columnFilters[key].id)];
        if(selectedData){
          columnFilters[key]['filterVariant'] = selectedData.filterVariant
        }
      }
      setColumnFilters(columnFilters);
      setPage(0);
      setIsLoading(true);
    }
  }, [state.databaseTableName,columnFilters])
  //  Column Filter end

  
  // table primary key logic start
  const [tablePK, setTablePK] = useState([]);

  useEffect(() => {
    if(state.databaseTableName){
    tablePrimaryKeyApi(state.databaseTableName,state.dbCredId)
      .then((data) => {
        setTablePK(data);
      })
      .catch((error) => console.log(error));
    }
  }, [state.databaseTableName]);
  // table primary key logic end

  // table total count result logic start
  const [totalResult, setTotalResult] = useState(0);
  useEffect(() => {
    if(state.databaseTableName){
      tableTotalCountApi(state.databaseTableId,columnFilters)
      .then((data) => {
        setTotalResult(data);
      })
      .catch((error) => console.log(error));
    }
  }, [state.databaseTableName,columnFilters]);
  // table total count resultlogic end
  
  // Select Options start
  const [colsFilterOpions, setColsFilterOpions] = useState(null);
  const [colsFilterOpionsFirst, setColsFilterOpionsFirst] = useState([]);
  const [colsSelectOpions, setColsSelectOpions] = useState([]);

  useEffect(() => {
    if(state.databaseTableName){
      tablesColumnsSelectOptionsApi(state.databaseTableId,columnFilters)
        .then((data) => {
          setColsFilterOpions(data);
          if(colsSelectOpions.length === 0 ){
            var select = [];
            Object.keys(data).forEach(function(key) {
              if (Array.isArray(data[key]) && data[key].length > 0) {
                select.push(key)
              }
            });
            setColsSelectOpions(select)
          }
        })
        .catch((error) => console.log(error));
    }
  }, [state.databaseTableName,columnFilters]);
  // Select Options end
  
  // table Columns logic start
  const [tableColumns, setTableColumns] = useState([]);
  const getTableColumns = (columnsList) => {
    var list = []
    if (tablePK.length > 0 ){
      columnsList = columnsList.filter(item => !tablePK.includes(item));
      const pkObj = tablePK.map(item => ({ accessorKey: item, header: item, enableColumnFilter: false,enableHiding: true}));
      list = pkObj
    }

    columnsList.forEach((col) => {
      var columnsListObj
      if(Array.isArray(colsFilterOpions[col]) && 
          colsFilterOpions[col].length > 0 && 
          colsSelectOpions.includes(col)){
        columnsListObj = { 'accessorKey':col,'header':col, filterVariant: 'select', filterSelectOptions: colsFilterOpions[col],enableHiding: true }
      }
      else if(/^.*(DATE).*$/.test(colsFilterOpions[col])){
  
        columnsListObj = { 'accessorKey':col,'header':col, enableColumnFilter: false,enableHiding: true }

      }
      else{
        columnsListObj = { 'accessorKey':col,'header':col, filterVariant: 'text',enableHiding: true }
      }

      list.push(columnsListObj);
    })
    if (state.accessLevel === "Read-Write" && tablePK.length > 0){
    const buttonObj = {
      accessorKey: 'action',
      header: 'Action',
      enableColumnFilter: false,
      enableColumnActions: false,
      enableSorting: false,
      enableHiding: true,
      Cell: ({row}) => {
        return (
        <Stack direction="row" justifyContent="left" >
        <Tooltip title="Edit" >
        <span>
          <IconButton
            size="small"
            color="secondary"
            onClick={() =>
              handleDashboardTableDialogOpen(
                row.original
              )
            }
          >
            <EditOutlinedIcon />
          </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Delete" >
        <span>
          <IconButton
            size="small"
            color="error"
            onClick={() =>
              handleDeleteDialogOpen(
                `Delete '${state.databaseTableName}' ${tablePK[0]} ${row.original[tablePK[0]]}`,
                "Are you sure you want to delete this record?",
                row.original[tablePK[0]],
                state.databaseTableName
              )
            }
          >
            <DeleteOutlinedIcon /> 
          </IconButton>
          </span>
        </Tooltip>
        </Stack>
      )},
    }
    list.push(buttonObj);
    }
    setTableColumns(list)
  };
  // table Columns logic end


  // tables Detail logic start
  const [tables, setTables] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [columnsListCreate, setColumnsListCreate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [runTables, setRunTables] = useState(0);

  useEffect(() => {
    if(state.databaseTableName && null !== colsFilterOpions){
      tablesDetailApi(state.databaseTableId, page, rowsPerPage, columnFilters)
        .then((data) => {
          setTables(data);
          setIsLoading(false);
          setRunTables(runTables+1)
          if (data.length > 0){
            const columnList = Object.keys(data[0])
            if (columnList.includes(tablePK[0])) {
              const index = columnList.indexOf(tablePK[0]);
              const element = columnList.splice(index, 1)[0];
              columnList.unshift(element);
            }
            setColumnsList(columnList)
            getTableColumns(columnList)
            const columnsListCreate = columnList.reduce((acc,curr)=> (acc[curr]='', acc),{});
            setColumnsListCreate(columnsListCreate);   
          }
        })
        .catch((error) => console.log(error));
    }
  }, [state.databaseTableName,page,rowsPerPage,refetchTablesList, colsFilterOpions,columnFilters,]);
  // tables Detail logic end

  // initial state logic start
  let initialState = {}
  function setInitialState(initialState,columnsList){
    columnsList.forEach((col) => {
        initialState[col] = "";
    });
    return initialState
  }

  const initial = setInitialState(initialState,columnsList)
  // initial state logic end

  //  dashboardTableDialogState logic start
  const [dashboardTableDialogState, setDashoboardTableDialogState] = useState(
    { ...initial,'open':false,'databaseTableName': state.databaseTableName, 'colsFilterOpionsFirst':colsFilterOpionsFirst}
  );
  if (JSON.stringify(dashboardTableDialogState) === '{}'){
    setDashoboardTableDialogState({'open':false })
  }
  // dashboardTableDialogState logic end

  // delete logic start
  const handleDelete = (id,databaseTableName,dbCredId) => {
    const fieldValues = [...tables];
    const tablePKVar = tablePK[0];
    const deletedTable = fieldValues.find(obj => obj[tablePKVar] === id)
    const tableInfo = JSON.parse(state.table)

    // axios
    .delete(`${endpoint}/api/tables/${dbCredId}/${databaseTableName}/${id}`)
    .then(() => {
      fieldValues.splice(fieldValues.findIndex(obj => obj[tablePKVar] === id), 1);

      if (totalResult !== 0) {
        const lastPage = Math.ceil(totalResult / rowsPerPage);
        if((page+1) === lastPage && fieldValues.length === 0){
          setTables(fieldValues);
          setPage(page-1)
        }
        else if(page+1< lastPage){
          setRefetchTablesList(refetchTablesList + 1);
        }
        else{
          setTables(fieldValues);
        }
      }
      customToast.success("Record deleted successfully");
      postEventApi({action:'delete a record ' + [tablePKVar] + ' ' + deletedTable[tablePKVar] + ' in ' + tableInfo.name,
                    status:'success',
                    tableId: tableInfo.id,
                    tableName: tableInfo.name,
                    tableDatabaseTableName: databaseTableName,
                    existingRecord: JSON.stringify(deletedTable),
                    tableDatabaseRecordId: deletedTable[tablePKVar],
                    userId:userId,
                    userName:userName,
                  })
    })
    .catch((error) => {
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
      postEventApi({action:'delete a record ' + [tablePKVar] + ' ' + deletedTable[tablePKVar] + ' in ' + tableInfo.name,
                    status:'failure',
                    tableId: tableInfo.id,
                    tableName: tableInfo.name,
                    tableDatabaseTableName: databaseTableName,
                    existingRecord: JSON.stringify(deletedTable),
                    tableDatabaseRecordId: deletedTable[tablePKVar],
                    userId:userId,
                    userName:userName,
                  })
    });

  };
  // delete logic end

  // Dashboard Table dialog logic start
  const handleDashboardTableDialogOpen = (value) => {
    const tablePKVar = tablePK[0];
    var recordID;
    if(value.hasOwnProperty(tablePKVar)){
      recordID = value[tablePKVar];
      delete Object.assign(value, { [tablePKVar]: value[tablePKVar] })[tablePKVar];
    }
    var result = { open:true,ID:recordID,databaseTableName:state.databaseTableName,'colsFilterOpionsFirst':colsFilterOpionsFirst};
    result = Object.assign({}, result, value);
    setDashoboardTableDialogState(result);
  };

  const handleDashboardTableDialogClose = (doRefetch) => {
    if (doRefetch) {
      setRefetchTablesList(refetchTablesList + 1);
    }
    setDashoboardTableDialogState({ open: false, initial, 'colsFilterOpionsFirst':[]})
  };
  // Dashboard Table dialog logic end

  // delete confirmation dialog logic start
  const [deleteDialogState, setDeleteDialogState] = useState({
    open: false,
    title: "",
    message: "",
    id: 0,
  });

  const handleDeleteDialogOpen = (title, message, id, databaseTableName) => {
    setDeleteDialogState({
      open: true,
      title: title,
      message: message,
      databaseTableName:databaseTableName,
      id: id,
    });
  };

  const handleDeleteDialogClose = (isConfirmed, id) => {

    // if confirmed than delete
    if (isConfirmed) {
      handleDelete(id,state.databaseTableName,state.dbCredId);
    }
    setDeleteDialogState({
      open: false,
      title: "",
      message: "",
      databaseTableName:"",
      id: 0,
    });
  };
  // delete confirmation dialog logic end

  return (
    <Box sx={{ paddingX: 5, paddingY: 4, minHeight: "82vh" }}>
      <Helmet>
        <title>Dashboard | {brandingDtl.pageTitle || app_title}</title>
      </Helmet>
      <Grid container spacing={2} sx={{ minWidth: 300, minHeight: "82vh" }}>
        <Grid item xs={12} sm={6} md={4} >
          <TextField
            fullWidth
            select
            label="Table"
            name="table"
            value={state.table}
            onChange={handleTableChange}
            error={state.databaseTableNameError ? true : false}
            helperText={state.databaseTableNameError}
          >
            {tableOptions.map(
              (option, idx) =>
                ((isAdmin && isDefault) || (permissions.length
                  ? permissions.includes(option.id)
                  : false)) && (
                  <MenuItem key={idx} value={JSON.stringify(option)}>
                    {option.name}
                  </MenuItem>
                )
            )}
          </TextField>
        </Grid>
        { state.databaseTableName && (
          <Grid item xs={12} sx={{ mt: 1, minHeight: "75vh"}} >
            <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ height: "9vh" }}
            >
            <Grid item>
              <Typography sx={{ display: "contents" }}>Show</Typography>
              <TextField
                select
                name="rowsPerPage"
                value={rowsPerPage}
                onChange={handleChangeRowsPerPage}
                size="small"
                sx={{ verticalAlign: "middle", margin: 1, minWidth: 75 }}
              >
                {rowsPerPageOption.map((option, idx) => (
                  <MenuItem key={idx} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {(state.accessLevel === "Read-Write" && tablePK.length > 0) && (
            <Grid item>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() =>
                  
                  handleDashboardTableDialogOpen(
                    columnsListCreate
                  )
                }
              >
                Create
              </Button>
            </Grid>
            )}
          </Grid>


      {/* Table part */}
      {runTables !== 1 ? ( 
      <MaterialReactTable 
        columns={tableColumns} 
        data={tables} 
        manualFiltering
        onColumnFiltersChange={setColumnFilters}
        state={{ 
          columnFilters,
          isLoading: isLoading,
        }}
        enableGlobalFilter={false}
        enablePagination={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader
        muiTableContainerProps={{ sx: { overflow: "auto", height: "56vh", width: '100%'} }}
        enableBottomToolbar={false}
      />
      ):
        <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center",padding: "5vh"}}>
          <CircularProgress />
        </Box>
      }

      <DashboardTableDialog
        open={dashboardTableDialogState.open}
        tableInfo={JSON.parse(state.table)}
        dataId={dashboardTableDialogState.ID}
        dataDatabaseTableName={dashboardTableDialogState.databaseTableName}
        dataColsFilterOpionsFirst={dashboardTableDialogState.colsFilterOpionsFirst}
        editValue={dashboardTableDialogState}
        dataPK={tablePK[0]}
        handleClose={handleDashboardTableDialogClose}
      />

      <ConfirmationDialog
        open={deleteDialogState.open}
        handleClose={handleDeleteDialogClose}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        databaseTableName={deleteDialogState.databaseTableName}
        data={deleteDialogState.id}
      />

      {totalResult > 0 && !isLoading && (
        <Grid container justifyContent="center" sx={{ mt: 2 }} spacing={1}>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page)}
              disabled={page === 0}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(totalResult / rowsPerPage)}
              page={page + 1}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              color="secondary"
              hidePrevButton
              hideNextButton
              size={isSmall2 ? 'small' : 'medium'}
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={(e) => handleChangePage(e, page + 2)}
              disabled={Math.ceil(totalResult / rowsPerPage) === page + 1}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      )}
            
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default Dashboard;


