import axios from "axios";
import { endpoint } from "../../config";
import { customToast } from "../../lib/toastLib";

const tablesApi = async (id) => {
  try {
    const apiLink = `${endpoint}/api/table`;
    const response = await axios.get(apiLink);
    const data = response.data;
    const dataArray = [];

    if (data) {
      data.forEach((elem) => {
        const obj = {
          id: elem.id,
          name: elem.name || "",
          description: elem.description || "",
          databaseTableName: elem.databaseName || "",
          accessLevel: elem.accessLevel || "",
          databaseId: elem.databaseCreds_id || "",
          databaseName: elem.databaseCred ? elem.databaseCred.databaseName : "",
          connectionName: elem.databaseCred ? elem.databaseCred.connectionName : "",
          schema: elem.databaseCred ? elem.databaseCred.schema : "",
        };
        dataArray.push(obj);
      });
    }
    return dataArray;
  } catch (error) {
    if (error.response) {
      customToast.error(error.response.data?.message);
    } else {
      customToast.error(error.message);
    }
    throw error;
  }
};

export default tablesApi;

