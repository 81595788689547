import React, { useState, useEffect } from "react";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  IconButton,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  endpoint,
} from "../../config/index.js";

import { customToast } from "../../lib/toastLib.js";
import { useSelector, useDispatch } from "react-redux";
import { incrementFetchUserDtlTrg } from "../../utils/reduxSlice/tempSlice.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DatabaseCredsDialog(props) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  // props value
  const {
    open,
    handleClose,
    dataId,
    dataConnectionName,
    dataHost,
    dataDatabaseName,
    dataUsername,
    dataDialect,
    dataPort,
    dataSchema,
  } = props;

  //get logged-in user
  const userId = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.fullName);

  // state logic start
  const initialState = {
    connectionName: "",
    host: "",
    databaseName: "",
    username: "",
    schema:"",
    password: "",
    dialect: "",
    port: "",
  };

  const [state, setState] = useState({ ...initialState });

  const handleConnectionNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      connectionName: value,
    });
  };

  const handleHostChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      host: value,
    });
  };

  const handleDatabaseNameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      databaseName: value,
    });
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      username: value,
    });
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      password: value,
    });
  };

  const handleDialectChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      dialect: value,
    });
  };

  const handlePortChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      port: value,
    });
  };

  const handleSchemaChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      schema: value,
    });
  };
  // state logic end

  // submit button disable state logic start
  const [isSubmitBtnDisbaled, setIsSubmitBtnDisbaled] = useState(true);

  useEffect(() => {
    setIsSubmitBtnDisbaled(
      (!state.connectionName ? true : false) ||
      (!state.host ? true : false) ||
      ((!dataId && !state.password) ? true : false) ||
      (!state.databaseName ? true : false) ||
      (!state.username ? true : false) ||
      (!state.dialect ? true : false) ||
      (!state.port ? true : false) ||
      (!state.schema ? true : false)
    );
  }, [state]);
  // submit button disable state logic end

  // handle submit logic start
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const handleCatchLogic = (error) => {
      setSubmitBtnLoading(false);
      if (error.response) {
        customToast.error(error.response.data?.message);
      } else {
        customToast.error(error.message);
      }
    };

    const requestBody = {
      connectionName: state.connectionName,
      host: state.host,
      databaseName: state.databaseName,
      username: state.username,
      schema: state.schema,
      password: state.password,
      dialect: state.dialect,
      port: state.port,
    };
    (async () => {
      setSubmitBtnLoading(true);

      if (!dataId) {
        axios
          .post(`${endpoint}/api/database`, requestBody)
          .then((response) => {
            setSubmitBtnLoading(false);
            customToast.success("Database added successfully");
            handleClose(true);
            requestBody.id = response.data.id;
          })
          .catch((error) => {
            handleCatchLogic(error)
          });
      } else {
        axios
          .put(`${endpoint}/api/database/${dataId}`, requestBody)
          .then(() => {
            setSubmitBtnLoading(false);
            customToast.success("Database updated successfully");
            handleClose(true);
            if (dataId === userId) {
              dispatch(incrementFetchUserDtlTrg())
            }
          })
          .catch((error) => {
            handleCatchLogic(error)
          });
      }
    })()
  };
  // handle submit logic end

  // reset & value assign logic start
  useEffect(() => {
    if (open) {
      setState({
        ...initialState,
        ...(dataConnectionName && { connectionName: dataConnectionName }),
        ...(dataHost && { host: dataHost }),
        ...(dataDatabaseName && { databaseName: dataDatabaseName}),
        ...(dataSchema  && { schema: dataSchema }),
        ...(dataUsername && { username: dataUsername }),
        ...(dataDialect && { dialect: dataDialect }),
        ...(dataPort && { port: dataPort }),
      });
    }
  }, [open]);
  // reset & value assign logic end

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      fullWidth
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit} autoComplete="off">
        <DialogContent>
          <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
            <IconButton onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Connection Name"
                name="connectionName"
                value={state.connectionName}
                onChange={handleConnectionNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Host"
                name="host"
                value={state.host}
                onChange={handleHostChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Database Name"
                name="databaseName"
                value={state.databaseName}
                onChange={handleDatabaseNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                disabled={dataId?true:false}
                label="Schema"
                name="schema"
                value={state.schema}
                onChange={handleSchemaChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Username"
                name="username"
                value={state.username}
                onChange={handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required={!dataId}
                fullWidth
                label={!dataId ? "Password" : "Update Password"}
                name="password"
                value={state.password}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Dialect"
                name="dialect"
                value={state.dialect}
                onChange={handleDialectChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Port"
                name="port"
                value={state.port}
                onChange={handlePortChange}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            disabled={isSubmitBtnDisbaled}
            loading={submitBtnLoading}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

